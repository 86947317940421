import find from 'lodash/find';
import join from 'lodash/join';
import shuffle from 'lodash/shuffle';
import sampleSize from 'lodash/sampleSize';

const _LENGTH = 8;
const _ALLOWED_CHARS = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789=-+/';

export const SIMPLE_TYPE = 'simple';
export const LODASH_TYPE = 'lodash';
export const CRYPTO_TYPE = 'crypto';

const _TYPES = [ SIMPLE_TYPE, LODASH_TYPE, CRYPTO_TYPE ];

/**
 * Генератор случайных паролей
 */
class PasswordGenerator {
    /**
     * Передаем один из допустимых типов генерации
     *
     * @param type
     */
    constructor( type ) {
        this._type = null;
        this._password = null;

        let foundType = PasswordGenerator._findType( type );
        if ( !foundType ) {
            throw new Error( 'Unknown password generator type' );
        }

        this._type = foundType;
    }

    /**
     * Получаем сгенерированный пароль
     *
     * @returns {null}
     */
    get() {
        return this._password;
    }

    /**
     * Генерируем пароль
     *
     * @param length
     * @return {PasswordGenerator}
     */
    generate( length = _LENGTH ) {
        this._password = null;

        switch ( this._type ) {
            case SIMPLE_TYPE:
                this._password = PasswordGenerator._generateSimplePassword( length );
                break;
            case LODASH_TYPE:
                this._password = PasswordGenerator._generateLodashPassword( length );
                break;
            case CRYPTO_TYPE:
                this._password = PasswordGenerator._generateCryptoPassword( length );
                break;
        }

        return this;
    }

    /**
     * Ищем тип
     *
     * @param search
     * @returns {*}
     * @private
     */
    static _findType( search ) {
        return find( _TYPES, type => type === search );
    }

    /**
     * Генерируем простой пароль
     *
     * @param length
     * @private
     */
    static _generateSimplePassword( length = _LENGTH ) {
        let password = '';

        for ( let index = 1; index < length; index++ ) {
            let character = Math.floor( Math.random() * _ALLOWED_CHARS.length + 1 );
            password += _ALLOWED_CHARS.charAt( character );
        }

        return password;
    }

    /**
     * Генерируем пароль с помощью lodash
     *
     * @param length
     * @private
     */
    static _generateLodashPassword( length = _LENGTH ) {
        return join( sampleSize( shuffle( _ALLOWED_CHARS ), length ), '' );
    }

    /**
     * Генерируем криптовариантный пароль
     *
     * @param length
     * @private
     */
    static _generateCryptoPassword( length = _LENGTH ) {
        let buf = new Uint8Array( length );
        window.crypto.getRandomValues( buf );

        return btoa( String.fromCharCode.apply( null, buf ) );
    }
}

export default PasswordGenerator;
