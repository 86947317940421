<template>
    <multi-select
        label="name"
        trackBy="code"
        v-model="selectedOptions"
        :options="options"
        :searchable="true"
        :close-on-select="true"
        :show-labels="true"
        :multiple="true"
        placeholder="Роли пользователя"
        select-label
        selected-label
        deselect-label
        @input="onInput"
        @remove="onRemove"
        v-if="options"
    ></multi-select>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    import MultiSelect from "vue-multiselect"
    import { ROLES_ENDPOINT } from "../../utils/endpoints"
    import {findIndex} from 'lodash';

    export default {
        components: { MultiSelect },
        props: {
            value: {},
            globalOnly: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            isDisabled() {
                return !this.$auth.check()
            },
        },
        data() {
            return {
                options: [],
                selectedOptions: this.value
            }
        },
        methods: {
            onInput(selectedOptions) {
                this.$emit("input", selectedOptions)
            },
            onRemove(removedOption) {
                if (findIndex(this.options, o => o.id === removedOption.id) !== -1) {
                    return;
                }

                this.selectedOptions.push(removedOption);
            },
        },
        created() {
            this.$http
                .get(ROLES_ENDPOINT)
                .then(response => response.data)
                .then(data => {
                    if (this.globalOnly) {
                        this.options = data.filter(role => !role.storage_restriction)
                    } else {
                        this.options = data
                    }

                    this.options = this.options.filter(role => this.$auth.check([role.code]));
                })
                .catch(error => {
                    console.error(error)
                    alert("Не удалось получить список ролей")
                })
        }
    }
</script>
