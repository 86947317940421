<template>
    <b-container fluid="" v-if="form">
        <b-row>
            <b-col class="text-right">
                <b-form-checkbox v-model="form.is_active">
                    <b>Активен?</b>
                </b-form-checkbox>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="text-left">
                <b-form-group label="Email">
                    <b-form-input v-model="form.email"/>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="text-left">
                <b-form-group label="Логин">
                    <b-form-input v-model="form.username"/>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="text-left">
                <b-form-group label="Имя пользователя">
                    <b-form-input v-model="form.fullname"/>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group label="Пароль" class="text-left">
                    <b-input-group>
                        <b-form-input v-model="form.password"/>
                        <b-input-group-append>
                            <b-button variant="primary" @click="form.password = passwordGeneration()">
                                Сгенерировать
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group label="ШК для входа" class="text-left">
                    <b-input-group>
                        <b-form-input v-model="form.login_barcode"/>
                        <b-input-group-append>
                            <b-button v-if="barcodeExists" target="_blank" variant="outline-dark" :to="routerBarcode( form )">
                                <b-icon-upc/>
                            </b-button>
                            <b-button variant="primary" @click="form.login_barcode = passwordGeneration()">
                                Сгенерировать
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="text-left">
                <b-form-group label="Табельный номер">
                    <b-form-input v-model="form.out_staff_id"/>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="text-left">
                <b-form-group label="Выбор общей роли (во всех цехах)">
                    <role-select v-model="form.rolesWithoutStorages" :global-only="true"/>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="text-left">
                <b-form-group label="Роль бригадира в следующих цехах">
                    <storage-select
                        :allowed-roles="[ROLE_STORAGE_SENIOR]"
                        :check-access="true"
                        :disabled="!$auth.check(ROLE_STORAGE_SENIOR)"
                        v-model="form.storagesGroupedByRole[ROLE_STORAGE_SENIOR]"
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="text-left">
                <b-form-group label="Роль кладовщика в следующих цехах">
                    <storage-select
                        :allowed-roles="[ROLE_STOCKMAN]"
                        :check-access="true"
                        :disabled="!$auth.check(ROLE_STOCKMAN)"
                        v-model="form.storagesGroupedByRole[ROLE_STOCKMAN]"
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col class="text-left">
                <b-form-group label="Роль старшего группы в следующих цехах">
                    <storage-select
                        :allowed-roles="[ROLE_GROUP_SENIOR]"
                        :check-access="true"
                        :disabled="!$auth.check(ROLE_GROUP_SENIOR)"
                        v-model="form.storagesGroupedByRole[ROLE_GROUP_SENIOR]"
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <template v-if="form.id">
            <h4 class="text-center">Назначение должности</h4>

            <b-row>
                <b-col cols="5">
                    <b-form-group label="Должность">
                        <work-position-select
                            v-model="workPosition"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="5">
                    <b-form-group label="Действует с">
                        <b-form-datepicker
                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                            :start-weekday="1"
                            locale="ru"
                            placeholder=""
                            v-model="workPositionValidFrom"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="2" class="text-right">
                    <b-button variant="primary" @click="assignTo" :disabled="assignToIsValid">Назначить</b-button>
                </b-col>
            </b-row>

            <hr v-if="workPositions.length">

            <b-row
                v-show="workPositions.length"
                :key="userWorkPosition.pivot.id"
                v-for="( userWorkPosition, index ) in workPositions"
                class="text-left mb-2"
            >
                <b-col cols="5">{{ userWorkPosition.name }}</b-col>
                <b-col cols="5">{{ moment( userWorkPosition.pivot.valid_from ).format( 'DD.MM.YYYY' ) }}</b-col>
                <b-col v-show="index === 0" cols="2" class="text-right">
                    <b-button
                        v-if="$auth.check([ROLE_ADMIN])"
                        variant="danger"
                        @click="dismiss(index)"
                    >
                        Освободить
                    </b-button>
                </b-col>
            </b-row>
        </template>

        <template v-if="form.id">
            <h4 class="text-center">Назначение коэфициента</h4>

            <b-row>
                <b-col cols="5" class="text-left">
                    <b-form-group label="Организация подрядчик">
                        <b-form-select
                            id='staff-contractors'
                            v-model="form.contractor_id"
                            :options="staffContractorOptions"
                        >
                            <template #first>
                                <b-form-select-option :value="null" >Нет</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row v-show="enabledOutstaffCoefficient">
                <b-col cols="3">
                    <b-form-group label="Коэффициент">
                        <b-form-select
                            v-model="form.outstaff_coefficient_id"
                            :options="outstaffCoefficientOptions"
                        >
                            <template #first>
                                <b-form-select-option :value="null" >Нет</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col cols="3">
                    <b-form-group label="Действует с">
                        <b-form-datepicker
                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                            :start-weekday="1"
                            :max="form.outstaff_coefficient_valid_to"
                            locale="ru"
                            v-model="form.outstaff_coefficient_valid_from"
                        />
                    </b-form-group>
                </b-col>
                <b-col cols="3">
                    <b-form-group label="Действует до">
                        <b-form-datepicker
                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                            :start-weekday="1"
                            :min="form.outstaff_coefficient_valid_from"
                            locale="ru"
                            v-model="form.outstaff_coefficient_valid_to"
                        />
                    </b-form-group>
                </b-col>
            </b-row>

            <hr v-if="value.outstaff_coefficients.length">

            <b-row
                v-show="value.outstaff_coefficients.length"
                v-for="( userOutstaffCoefficients, index ) in value.outstaff_coefficients"
                :key="index"
                class="text-left mb-2"
            >
                <b-col cols="3">{{ userOutstaffCoefficients.coefficient }}</b-col>
                <b-col cols="3">
                    {{ userOutstaffCoefficients.pivot.valid_from ? moment(userOutstaffCoefficients.pivot.valid_from).format('DD.MM.YYYY') : '' }}
                </b-col>
                <b-col cols="3">
                    {{ userOutstaffCoefficients.pivot.valid_to ? moment(userOutstaffCoefficients.pivot.valid_to).format('DD.MM.YYYY') : '' }}
                </b-col>
                <b-col cols="3" class="container-margin">
                    <b-button
                        variant="danger"
                        @click="destroyOutstaffCoefficient(userOutstaffCoefficients.pivot.id)"
                    >
                        <b-icon-x/>
                    </b-button>
                </b-col>
            </b-row>
        </template>
    </b-container>
</template>

<script>
    import moment from 'moment';
    import cloneDeep from 'lodash/cloneDeep';

    import RoleSelect from '../_common/RoleSelect';
    import StorageSelect from '../_common/StorageSelect';
    import WorkPositionSelect from '../_common/WorkPositionSelect';

    import ErrorsBag from '../../utils/errorsBag';
    import PasswordGenerator, { CRYPTO_TYPE } from '../../utils/passwordGenerator';

    import { USERS_ENDPOINT, CONTRACTORS_ENDPOINT } from '../../utils/endpoints';
    import { ROLE_ADMIN, ROLE_GROUP_SENIOR, ROLE_STOCKMAN, ROLE_STORAGE_SENIOR } from '../../utils/Roles';

    const DATE_FORMAT = 'YYYY-MM-DD';

    export default {
        name: "UsersForm",
        components: {
            WorkPositionSelect,
            RoleSelect,
            StorageSelect,
        },
        props: {
            value: {
                type: Object,
                default: () => null,
            },
            loginBarcodeExists: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            isDiff() {
                return JSON.stringify( this.form ) !== JSON.stringify( this.value );
            },
            assignToIsValid() {
                return !( this.workPosition && this.workPositionValidFrom );
            },
            outstaffCoefficientOptions() {
                return this.outstaffCoefficients.map(({ id, coefficient }) => ({ value: id, text: coefficient }));
            },
            staffContractorOptions() {
                return this.staffContractors.map(({ id, name }) => ({ value: id, text: name }))
            },
            enabledOutstaffCoefficient() {
                return this.value && this.value.contractor_id;
            }
        },
        watch: {
            value: {
                deep: true,
                handler() {
                    if ( this.isDiff ) {
                        this.form = cloneDeep( this.value );
                    }
                }
            },
            loginBarcodeExists() {
                this.barcodeExists = this.loginBarcodeExists;
            },
            form: {
                deep: true,
                handler() {
                    if ( this.isDiff ) {
                        this.$emit( 'input', this.form );
                    }

                    this.clearWorkPosition();
                }
            }
        },
        data() {
            return {
                moment,
                ErrorsBag,

                ROLE_ADMIN,
                ROLE_GROUP_SENIOR,
                ROLE_STOCKMAN,
                ROLE_STORAGE_SENIOR,

                DATE_FORMAT,

                form: cloneDeep( this.value ),
                barcodeExists: this.loginBarcodeExists,

                workPosition: null,
                workPositionValidFrom: null,

                workPositions: [],
                outstaffCoefficients: [],
                staffContractors: [],
            };
        },
        methods: {
            clearOutstaffCoefficient() {
                this.form.outstaff_coefficient_valid_from = null;
                this.form.outstaff_coefficient_valid_to = null;
                this.form.outstaff_coefficient_id = null;
            },
            routerBarcode( record ) {
                return { name: 'UsersBarcode', params: { id: record.id } };
            },

            passwordGeneration() {
                return new PasswordGenerator( CRYPTO_TYPE ).generate().get();
            },

            clearWorkPosition() {
                this.workPosition = null;
                this.workPositionValidFrom = null;
            },

            assignTo() {
                if ( !this.form.id ) {
                    return null;
                }

                ErrorsBag.discard();

                let params = {
                    id: this.workPosition.id,
                    valid_from: moment( this.workPositionValidFrom ).format( DATE_FORMAT )
                };

                this.$http
                    .post( USERS_ENDPOINT + '/' + this.form.id + '/work_positions/assign_to', params )
                    .then( this.loadWorkPositions )
                    .catch( response => ErrorsBag.fill( response.body ) );
            },

            dismiss( index ) {
                this.$bvModal.msgBoxConfirm(
                    'Вы уверены, что хотите освободить пользователя от должности?',
                    {
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Да',
                        cancelTitle: 'Отмена',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true
                    }
                ).then((value) => {
                    if (value === true) {
                        ErrorsBag.discard();

                        let workPosition = this.workPositions[ index ];
                        let workPositionId = workPosition.pivot.id;

                        this.$http
                            .delete( USERS_ENDPOINT + '/' + this.form.id + '/work_positions/' + workPositionId + '/dismiss' )
                            .then( this.loadWorkPositions )
                            .catch( response => ErrorsBag.fill( response.body ) );
                    }
                });
            },

            loadWorkPositions() {
                this.$http
                    .get( USERS_ENDPOINT + '/' + this.form.id + '/work_positions' )
                    .then( response => response.data )
                    .then( workPositions => {
                        this.workPositions = workPositions;

                        const currentDate = moment().startOf( 'day' );
                        const actualWorkPositions = workPositions.filter(
                            workPosition => moment( workPosition.pivot.valid_from ).startOf( 'day' ) <= currentDate
                        );
                        if ( actualWorkPositions.length > 0 ) {
                            this.form.current_work_position = actualWorkPositions[ 0 ];
                        } else {
                            this.form.current_work_position = null;
                        }
                    })
                    .catch( response => ErrorsBag.fill( response.body ) );
            },

            loadOutstaffCoefficients() {
                this.$http
                    .get( USERS_ENDPOINT + '/outstaff-coefficients' )
                    .then( response => response.data )
                    .then( outstaffCoefficients => {
                        this.outstaffCoefficients = outstaffCoefficients
                    })
                    .catch( response => ErrorsBag.fill( response.body ) );
            },

            loadStaffContractors() {
                const params = {
                    filters: {
                        type: 'staff'
                    }
                };
                this.$http
                    .get( CONTRACTORS_ENDPOINT, { params } )
                    .then( response => response.data.data )
                    .then( staffContractors => {
                        this.staffContractors = staffContractors.data;
                    })
                    .catch( response => ErrorsBag.fill( response.body ) );
            },

            destroyOutstaffCoefficient(id) {
                this.$bvModal.msgBoxConfirm(
                    'Вы действительно хотите удалить коэффициент?',
                    {
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Да',
                        cancelTitle: 'Отмена',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true
                    }
                ).then((value) => {
                    if (value === true) {
                       this.$http
                            .delete( `${USERS_ENDPOINT}/${this.value.id}/outstaff-coefficients/${id}` )
                            .then( response => response.data )
                            .then( () => {
                                this.clearOutstaffCoefficient();
                                this.form.outstaff_coefficients = this.form.outstaff_coefficients.filter(
                                    (outstaff_coefficient) => outstaff_coefficient.pivot.id !== id
                                );
                            })
                            .catch( response => ErrorsBag.fill( response.body ) );
                    }
                });
            },
        },
        created() {
            this.clearWorkPosition();
            if ( this.form.id ) {
                this.loadWorkPositions();
            }
            this.loadOutstaffCoefficients();
            this.loadStaffContractors();
        }
    }
</script>
